import React from "react"
import Layout from "../components/layout"
import Grid from "@material-ui/core/Grid"
import Seo from "../components/seo"

export default function Page({ data, pageContext }) {
  return (
    <>
      <Seo title={pageContext.title} />
      <Layout className="page-layout" pageName={pageContext.title}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className="space-top-20 space-bottom"
        >
          <Grid item xs={12} md={7} lg={7}>
            {pageContext.featuredImage && (
              <img
                src={pageContext.featuredImage.node.localFile.publicURL}
                alt={pageContext.title}
                width="100%"
                className="sdg-featured-img"
              />
            )}
          </Grid>
          <Grid item xs={12} md={7} lg={7}>
            <h1>{pageContext.title}</h1>
          </Grid>
          <Grid item xs={12} md={7} lg={7}>
            <div dangerouslySetInnerHTML={{ __html: pageContext.content }} />
          </Grid>
        </Grid>
      </Layout>
    </>
  )
}
